import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../components/util/loader';
import ErrorMessage from '../components/util/errormessage';
import { withAuth } from '../components/util/auth';
import { Link } from 'react-router-dom';

const GET_USERS_QUERY = gql`
query {
    getUsers {
        items {
            email
            id
            telephone_number
            can_administer
            can_edit_on_call
        }
        nextToken
    }
}
`

function Users() {
    const [moreLoading, setMoreLoading] = useState(true);
    const [nextToken, setNextToken] = useState(null);
    const { data, error, loading: getLoading, fetchMore } = useQuery(
        GET_USERS_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            // When using cache-and-network - have to use cache-first if using fetchMore
            // otherwise the fetchMore update causes a network request again
            // Thus, prevent network requests after the first as a workaround
            // See: https://github.com/apollographql/apollo-client/issues/6916
            nextFetchPolicy: 'cache-first',
        }
    );
    useEffect(() => {
        // Renders happen all the time with Apollo for some reason, like when fetchMore called before it even finishes fetching
        // To avoid multiple fetches for same data, ensure we store the token we used for last fetchMore
        if (data?.getUsers?.nextToken && data.getUsers.nextToken !== nextToken) {
            setNextToken(data.getUsers.nextToken);
            fetchMore({
                variables: {
                    next_token: data.getUsers.nextToken
                }
            });
        }
        if (data && !data?.getUsers?.nextToken) {
            setMoreLoading(false);
        }
    }, [data, fetchMore, nextToken]);

    const loading = moreLoading || getLoading;

    return (
        <div className="grid-container home loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error} />
                    <h1>Users</h1>
                    <table className="stack hover unstriped">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Telephone Number</th>
                                <th>Can Administer</th>
                                <th>Can Edit On Call</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.getUsers?.items.length ? data.getUsers.items.map(entry => (
                                <tr key={entry.email}>
                                    <td>{entry.email} {entry.id ? null : <b>(Invited)</b>}</td>
                                    <td>{entry.telephone_number ? <a href={`tel:${entry.telephone_number}`}>{entry.telephone_number}</a> : '-'}</td>
                                    <td><span className="hide-for-large">Can Administer: </span>{entry.can_administer ? 'Yes' : 'No'}</td>
                                    <td><span className="hide-for-large">Can Edit On Call: </span>{entry.can_edit_on_call ? 'Yes' : 'No'}</td>
                                    <td>
                                        <Link to={`/users/reset/${encodeURIComponent(entry.email)}`}>Reset</Link>&nbsp;
                                        <Link to={`/users/edit/${encodeURIComponent(entry.email)}`}>Edit</Link>&nbsp;
                                        <Link to={`/users/delete/${encodeURIComponent(entry.email)}`}>Delete</Link>&nbsp;
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5">No users found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Link to="/users/add" className="button primary">Add</Link>
                </div>
            </div>
        </div>
    );
}

export default withAuth(Users, 'administer');
